import React from "react"
import { navigate } from "gatsby"
import loadable from "@loadable/component"

import SEO from "../components/seo"

import CustomRender from "../components/utils/storyRenderer"

import "../components/story.css"

const Stories = loadable(() => import("react-insta-stories"))

const Story = ({ data }) => {
  const story = data.stories
  const stories = data.allStories.edges

  const handleCloseStory = () => {
    navigate("/")
  }

  const handleJumpStory = () => {
    const storyArray = stories.filter(s => s.node.slug !== story.slug)

    navigate(
      `/stories/${
        storyArray[Math.floor(Math.random() * storyArray.length)].node.slug
      }`
    )
  }

  return (
    <>
      <SEO
        title={story.name}
        description={story.name}
        url={`${process.env.GATSBY_HOST_URL}/stories/${story.slug}`}
        image={story.imageUrl}
        canonical={`${process.env.GATSBY_HOST_URL}/stories/${story.slug}`}
      />

      <div className="story-show-container">
        <span className="close-story" onClick={() => handleCloseStory()}>
          &times;
        </span>

        <button className="story-jump" onClick={() => handleJumpStory()}>
          Pular story
        </button>

        <Stories
          stories={story.images}
          defaultInterval={3000}
          width={432}
          height={768}
          renderers={[CustomRender]}
          onAllStoriesEnd={() => handleCloseStory()}
        />
      </div>
    </>
  )
}

export default Story

export const query = graphql`
  query($id: String!) {
    stories(id: { eq: $id }) {
      name
      slug
      images {
        image
        link
      }
      imageUrl
      color
    }

    allStories {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`
