import React, { useState } from "react"

import WithHeader from "./withHeader"

export const Renderer = ({ story, action, isPaused, config }) => {
  const { width, height, loader, storyStyles } = config

  let computedStyles = {
    ...styles.storyContent,
    ...(storyStyles || {}),
  }

  const [loaded, setLoaded] = useState(false)

  const imageLoaded = () => {
    setLoaded(true)
    action("play")
  }

  return (
    <WithHeader story={story} globalHeader={config.header}>
      <div
        className="inside-story-container"
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img style={computedStyles} src={story.image} onLoad={imageLoaded} />
        {story.link && (
          <a className="story-link" href={story.link} target="_blank">
            Saiba mais
          </a>
        )}
        {!loaded && (
          <div
            style={{
              width: width,
              height: height,
              position: "absolute",
              left: 0,
              top: 0,
              background: "rgba(0, 0, 0, 0.9)",
              zIndex: 9,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#ccc",
            }}
          >
            {loader || <div className="spinner" />}
          </div>
        )}
      </div>
    </WithHeader>
  )
}

const styles = {
  story: {
    display: "flex",
    position: "relative",
    overflow: "hidden",
  },
  storyContent: {
    width: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "auto",
  },
}

export const tester = story => {
  return {
    condition: true,
    priority: 4,
  }
}

export default { renderer: Renderer, tester }
